<template>
        <main-template>
            <template v-slot:headerLottieDark>
                <dotlottie-player :src="getDarkLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:headerLottieLight>
                <dotlottie-player :src="getLightLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:header>TraQA</template>
            <template v-slot="slotProps">
            <fetch-siren-root :linkName='linkName' :link="link">

                <div slot-scope="{ response }" class="flex flex-grow overflow-hidden">
                    <div class="flex w-full overflow-hidden">
                        <div class="flex w-full h-full p-2 overflow-hidden">
                            <div class="rounded-xl bg-v3-base-500 w-full h-full margin-10 dark:bg-v3-gray-900 overflow-hidden">
                                <loading v-if="!response"></loading>
                                <template v-else>
                                    <SingleQaDetails ref="qaDetails" :details="response.body" :loading-details="false" :hideTable="hideTable" @setLoading="setLoading" @getDetails="getDetails" :hasTable="false"></SingleQaDetails>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </fetch-siren-root>
            </template>
        </main-template>
</template>

<script>
import FetchSirenRoot from '@/components/FetchSirenRoot';
import Loading from '@/components/Loading';
import MainTemplate from '@/v3/templates/MainTemplate';
import {mapGetters} from "vuex";
import Siren from "super-siren";
import ProcurementPagination from "@/v3/components/pagination/OrdersPagination.vue";
import ProcurementNavigation from "@/v3/components/navigation/ProcurementNavigation.vue";
import fileDownload from "js-file-download";
import SingleOrderDetails from "@/v3/pages/SingleOrderDetails.vue";
import SingleQaDetails from "@/v3/pages/SingleQaDetails.vue";

export default {
    inject: ["$validator"],
    components: {
        SingleQaDetails,
        SingleOrderDetails,
        ProcurementNavigation,
        ProcurementPagination,
        FetchSirenRoot,
        Loading,
        MainTemplate,

    },
    data() {
        return {
            slotProps: null,
            users: null,
            details: null,
            detailsOpen: false,
            loadingDetails: false,
            orders: null,
            tabOpen: 'order-list',
            editing: false,
            submittingModal: false,
            hideTable: false,
            editingOrderItems: false
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser"
        }),
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        }

    },
    mounted() {
        let follow = Siren.Helper.follow;
    },
    methods: {
        showDetails(details){
            this.$setLaravelValidationErrorsFromResponse('');
            this.details = details;
            this.loadingDetails = false;
        },
        setLoading(){
            this.$refs.qaDetails.editing = false;
            this.$refs.qaDetails.tabOpen = 'basics';
            this.details = null;
            this.loadingDetails = true;
        },
        getDetails(entity){
            this.$router.go(0);
        },
        getDarkLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=qa_dark"
        },
        getLightLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=qa_light"
        }

    }
}
</script>

